import React from 'react';
import './Home.css';
import bus from "../images/bus.jpg"

const Home = () => {
  return (
    <div className='Home'>
        <div className='soon'>
        <h2 className='heading text-center'>Big things are happening at Bus Albania!</h2>
        <h3 className='subtitles text-center'>We're gearing up to revolutionize the way you travel. Stay tuned for an announcement that will change the way you think about bus transportation.</h3>
    </div>
    <div className='Hom-bus'>
    <img className='bus-photo' src={bus} alt="bus" />


    </div>
    </div>
  )
}

export default Home
