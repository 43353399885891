import React from "react";
import logo from "../images/logo.png";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="logo-img">
        <a className="navbar-brand" href="/">
          <img className="logo" src={logo} alt="Bus al" />
        </a>
      </div>
    </div>
  );
};

export default Navbar;
